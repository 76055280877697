import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "index",
    component: () => import("../views/Home.vue"),
    meta: {
      requireAuth: true,
    },
    children: [
      {
        path: "subreddits/:subredditId",
        name: "subreddit",
        component: () => import("../views/Home.vue"),
        meta: {
          requireAuth: true,
        },
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login.vue"),
    meta: {
      requireAuth: false,
    },
  },
  {
    path: "/user",
    name: "User",
    component: () => import("../views/User.vue"),
    meta: {
      requireAuth: true,
    },
  },
  {
    path: "/forgot",
    name: "forgot-password",
    component: () => import("../views/ForgotPassword.vue"),
  },
];

if (+process.env.VUE_APP_ALLOW_REGISTER) {
  routes.push({
    path: "/register",
    name: "register",
    component: () => import("../views/Register.vue"),
    meta: {
      requireAuth: false,
    },
  });
}

routes.push({ path: "*", redirect: { name: "index" } });

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.duplicationErroringPush = router.push;
router.push = async function (location) {
  return router.duplicationErroringPush(location).catch((error) => {
    if (error.name === "NavigationDuplicated") {
      return this.currentRoute;
    }
    throw error;
  });
};

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requireAuth === true)) {
    if (localStorage.getItem("token") === null) {
      next({ name: "login", query: to.query });
      return;
    }
    store
      .dispatch("RedditStore/ME")
      .then(() => {
        next();
      })
      .catch(() => {
        store.dispatch("RedditStore/LOGOUT");
        next({ name: "login", query: to.query });
      });
  } else {
    next();
  }
});

export default router;
