import Vue from "vue";

export const RedditStore = {
  namespaced: true,
  state: () => ({
    isAuth: false,
    user: {},
    subreddits: [],
  }),
  mutations: {
    LOGOUT(state) {
      state.isAuth = false;
      localStorage.removeItem("token");
      localStorage.removeItem("abilities");
    },
    ME(state, user) {
      state.isAuth = true;
      state.user = user;
    },
    SUBREDDITS(state, subreddits) {
      state.subreddits = subreddits;
    },
  },
  getters: {
    getUser(state) {
      return state.user;
    },
    getIsAuth(state) {
      return state.isAuth;
    },
    getSubreddits(state) {
      return state.subreddits;
    },
  },
  actions: {
    async DOWNLOAD_BACKUP() {
      let res = await Vue.axios({
        url: "backup",
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "backup.sql");
        document.body.appendChild(link);
        link.click();
      });

      return res;
    },
    async UPDATE_REDDIT_LIST() {
      let res = await Vue.axios.post(`subreddits/update-list`);

      return res;
    },
    async INVITE_MANAGER(options, data) {
      let res = await Vue.axios.post(`profile/invite-manager`, data);

      return res;
    },
    async RESET_PASSWORD(options, data) {
      let res = await Vue.axios.post(`profile/reset-password`, data);

      return res;
    },
    async DELETE_MANAGER(options, userId) {
      let res = await Vue.axios.delete(`profile/delete-manager/${userId}`);

      return res;
    },
    async GET_MANAGER_LIST(options, data = {}) {
      let res = await Vue.axios.get(`profile/managers`, { params: data });

      return res;
    },
    async UPDATE_PROFILE(options, data) {
      let res = await Vue.axios.post(`profile/update-profile`, data);

      return res;
    },
    async UPDATE_PASSWORD(options, data) {
      let res = await Vue.axios.post(`profile/update-password`, data);

      return res;
    },
    async ADD_SUBREDDIT(options, data) {
      let res = await Vue.axios.post(`subreddits`, data);

      return res;
    },
    async DELETE_SUBREDDIT(options, subredditId) {
      let res = await Vue.axios.delete(`subreddits/${subredditId}`);

      return res;
    },
    async FETCH_POSTS(options, data) {
      let res = Vue.axios.get(`subreddits/${data.subreddit_id}/posts`, {
        params: data,
      });

      return await res;
    },
    // eslint-disable-next-line no-unused-vars
    async FETCH_SUBREDDITS({ commit }) {
      let res = await Vue.axios.get(`subreddits`);
      commit("SUBREDDITS", res.data.data);

      return res;
    },
    async CHANGE_POST_STATUS(options, data) {
      let res = Vue.axios.post(`subreddits/${data.subreddit_id}/posts/${data.post_id}`, data);

      return await res;
      // subreddits/{subredditId}/posts/{postId}
    },
    async REGISTER(options, data) {
      let res = await Vue.axios.post(`auth/register`, data);

      return res;
    },
    async LOGIN(options, data) {
      let res = await Vue.axios.post(`auth/login`, data);

      return res.data;
    },
    async LOGOUT({ commit }) {
      commit("LOGOUT");
    },
    async ME({ commit }) {
      let res = await Vue.axios.post(`auth/me`);
      commit("ME", res.data.data);

      return res.data.data;
    },
  },
};
