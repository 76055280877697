import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueMeta from "vue-meta";
import axios from "axios";
import VueAxios from "vue-axios";
const moment = require("moment");
require("moment/locale/ru");
import { BootstrapVue } from "bootstrap-vue";
import Loading from "vue-loading-overlay";
import VueToast from "vue-toast-notification";
import CKEditor from "@ckeditor/ckeditor5-vue2";

axios.defaults.baseURL = process.env.VUE_APP_BASE_API_URL;
axios.defaults.withCredentials = true;
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

if (localStorage.getItem("token")) {
  axios.defaults.headers = {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
    Accept: "application/json",
  };
}

Vue.use(BootstrapVue);
Vue.use(VueMeta);
Vue.use(VueAxios, axios);
Vue.use(require("vue-moment"), { moment });
Vue.use(Loading);
Vue.use(VueToast, { position: "top-right", duration: 3000 });
Vue.use(CKEditor);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
