<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
  computed: {},
  methods: {
    // ...actionsProfileStore({
    //   me: "ME",
    // }),
  },
  async mounted() {
    // if (BaseHelper.isAuth()) {
    //   await this.me();
    //   this.handleNotifications();
    // }
  },
};
</script>
<style lang="scss">
@import "./assets/scss/main.scss";
@import "./assets/scss/custom.scss";
</style>
